import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { List } from "@mui/material";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useTranslation } from "react-i18next";
import { CustomTabTitle, isFieldValid, LoadingComponent } from "ndr-designsystem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { isEmergencyGenerator } from "features/controllableResources/details/utils";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    resetCurrentResource,
    setCurrentResource as setCurrentTechnicalResource,
} from "./store/technicalResourcesSlice";
import { PageStatus } from "../../utils/types";
import {
    TRConsumptionTabSchema,
    TRGeneralTabSchema,
    TROperationSchema,
    TRProductionTabSchema,
    TRTechnicalParametersSchema,
} from "../../utils/inputChecking";
import MarketLocationSubGrid from "./MarketLocationSubGrid";
import { FieldForActions } from "./utils";
import TechnicalParametersSubGrid from "./TechnicalParametersSubGrid";
import { setCurrentPage } from "../appStateSlice";
import {
    buildTRState,
    createTechnicalResourceAction,
    fetchTechnicalResourcesByControllableResource,
    forceRefreshTR,
    resetTRBeforeEdit,
    resetTRState,
    updateTechnicalResource,
} from "./store/thunks";
import DetailsViewToolbar from "../../Components/DetailsViewToolbar";
import { getMarketPartnersId } from "../../utils";
import ControllableResourceStatus from "../../api/fixed/ControllableResource/ControllableResourceStatus";
import useStyles from "./styles";
import General from "./General";
import DowntimeTable from "./DowntimeTable";
import OperationTab from "./OperationTab";
import SchemaConstants from "../../utils/schemaConsts";
import UnavailabilityTable from "./UnavailabilityTable";

interface RouteParams {
    controllableId: string;
    technicalId: string;
    page: string;
}

enum TechnicalResourcesPage {
    GENERAL,
    CONSUMPTION,
    PRODUCTION,
    OPERATION,
    PARAMETERS,
    DOWNTIME,
    UNAVAILABILITY
}

const EDITING_PAGES = ["general", undefined, "consumption", "production", "operation", "parameters"];

const TechnicalResourceDetailsView = (): ReactElement => {
    const { currentResource: resource, allResources } = useAppSelector((state) => state.technicalResources);
    const { allPartners: marketPartners } = useAppSelector((state) => state.marketPartners);
    const { currentResource: controllableResource } = useAppSelector((state) => state.controllableResources);
    const currentResourceIndex = allResources?.findIndex((r) => r.externalID === resource?.externalID) ?? -1;

    const { controllableId, technicalId, page } = useParams<RouteParams>();
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.VIEW);
    const [isSaving, setIsSaving] = useState(false);
    const isAdding = pageStatus === PageStatus.ADD;
    const isEditing = pageStatus === PageStatus.EDIT;
    const theme = useTheme();
    const matchesSmallDevice = useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
    const dispatch = useAppDispatch();
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation("tables");

    const transformPageStringToPageIndex = useCallback(() => {
        if (page) {
            if (page === "production" && !resource?.consumesEnergy) {
                return TechnicalResourcesPage.CONSUMPTION;
            }
            if (page === "operation") {
                if (!resource?.consumesEnergy && !resource?.producesEnergy) {
                    return TechnicalResourcesPage.CONSUMPTION;
                }
                if (!resource?.consumesEnergy || !resource?.producesEnergy) return TechnicalResourcesPage.PRODUCTION;
            }
            if (page === "parameters") {
                if (!resource?.consumesEnergy && !resource?.producesEnergy) {
                    return TechnicalResourcesPage.PRODUCTION;
                }
                if (!resource?.consumesEnergy || !resource?.producesEnergy) return TechnicalResourcesPage.OPERATION;
            }
            if (page === "downtime") {
                if (!resource?.consumesEnergy && !resource?.producesEnergy) {
                    return TechnicalResourcesPage.OPERATION;
                }
                if (!resource?.consumesEnergy || !resource?.producesEnergy) return TechnicalResourcesPage.PARAMETERS;
            }
            if (page === "unavailability") {
                if (!resource?.consumesEnergy && !resource?.producesEnergy) {
                    return TechnicalResourcesPage.PARAMETERS;
                }
                if (!resource?.consumesEnergy || !resource?.producesEnergy) return TechnicalResourcesPage.DOWNTIME;
            }

            // @ts-ignore
            if (TechnicalResourcesPage[page.toUpperCase()] === undefined) {
                history.replace(`/controllable/${controllableId}/resources/${technicalId}`);
            }

            // @ts-ignore
            return TechnicalResourcesPage[page.toUpperCase()] ?? TechnicalResourcesPage.GENERAL;
        }
        return 0;
    }, [page, resource, controllableId, technicalId, history]);

    useEffect(() => {
        dispatch(resetTRState());
    }, [technicalId, dispatch]);

    useEffect(() => {
        if (resource !== undefined && resource !== null && pageStatus === PageStatus.VIEW)
            dispatch(resetCurrentResource(technicalId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageStatus, technicalId, dispatch]);

    useEffect(() => {
        const buildState = async (): Promise<void> => {
            if (technicalId === "add") {
                dispatch(
                    setCurrentTechnicalResource({
                        production: {},
                        consumption: {},
                        producesEnergy: false,
                        consumesEnergy: false,
                    }),
                );
                setPageStatus(PageStatus.ADD);
            }
            const historyState = history.location.state as { force?: boolean };
            await dispatch(buildTRState({ technicalId, controllableId, force: historyState?.force ?? false }));

            dispatch(setCurrentPage("TechnicalResourcesDetails"));
        };
        buildState();

        return () => {
            dispatch(resetTRState());
        };
        // we need to trigger this only when technicalId gets available (at the same time with controllableId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [technicalId]);

    const toggleEditing = async (): Promise<void> => {
        if (pageStatus) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(t("changesWillBeReverted"));
            if (confirm) {
                setPageStatus(PageStatus.VIEW);
                await dispatch(resetTRBeforeEdit());
            }
            return;
        }
        setPageStatus(PageStatus.EDIT);
    };

    const createTechnicalResource = async (): Promise<void> => {
        if (resource) {
            setIsSaving(true);
            try {
                await dispatch(createTechnicalResourceAction(resource)).unwrap();
                history.replace(`/controllable/${controllableId}/resources/${resource.externalID}`);
                setPageStatus(PageStatus.VIEW);
            } catch {
                dispatch(
                    fetchTechnicalResourcesByControllableResource({
                        CRID: controllableResource?.inventoryItemId ?? "",
                        force: true,
                    }),
                );
            } finally {
                setIsSaving(false);
            }
        }
    };

    const saveChanges = async (): Promise<void> => {
        if (resource) {
            setIsSaving(true);
            try {
                await dispatch(updateTechnicalResource(resource)).unwrap();
                history.replace(`/controllable/${controllableId}/resources/${resource.externalID}`);
                setPageStatus(PageStatus.VIEW);
            } catch {
                dispatch(
                    fetchTechnicalResourcesByControllableResource({
                        CRID: controllableResource?.inventoryItemId ?? "",
                        force: true,
                    }),
                );
            }
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (isSaving) history.replace(`/controllable/${controllableId}/resources/${technicalId}`);
    }, [isSaving, controllableId, technicalId, history]);

    const forceRefresh = async (): Promise<void> => {
        setIsSaving(true);
        await forceRefreshTR();
        setIsSaving(false);
    };

    const handlePreviousClick = (): void => {
        if (currentResourceIndex > 0 && allResources)
            history.push(
                `/controllable/${controllableId}/resources/${
                    allResources[currentResourceIndex - 1].externalID ?? ""
                }/${page}`,
            );
    };

    const handleNextClick = (): void => {
        if (allResources && currentResourceIndex < allResources.length - 1)
            history.push(
                `/controllable/${controllableId}/resources/${
                    allResources[currentResourceIndex + 1].externalID ?? ""
                }/${page}`,
            );
    };
    const getAllTechnicalResourcesIDs = useCallback(
        (): string[] =>
            (allResources ?? [])
                .filter((g) => g.inventoryItemId !== resource?.inventoryItemId)
                .map((g) => g.externalID ?? ""),
        [allResources, resource],
    );

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);
    const GeneralTabSchema = useMemo(
        () =>
            TRGeneralTabSchema(
                prepareMP(),
                controllableResource?.compensationType?.code === "Z01",
                getAllTechnicalResourcesIDs(),
            ),
        [prepareMP, controllableResource, getAllTechnicalResourcesIDs],
    );
    const TechnicalParametersTabSchema = useMemo(
        () =>
            TRTechnicalParametersSchema(
                controllableResource?.energySource ?? {},
                resource?.technicalResourceType?.code === "SSE",
                resource?.missingValueForDirection,
                resource?.missingValueForIncline,
            ),
        [controllableResource, resource],
    );
    const ConsumptionTabSchema = useMemo(
        () => TRConsumptionTabSchema(prepareMP(), resource?.consumesEnergy ?? false),
        [prepareMP, resource],
    );
    const ProductionTabSchema = useMemo(
        () => TRProductionTabSchema(prepareMP(), resource?.producesEnergy ?? false),
        [prepareMP, resource],
    );
    const OperationTabSchema = useMemo(() => TROperationSchema(), []);

    const isGeneralTabValid = GeneralTabSchema.isValidSync(resource);
    const isTechnicalParametersTabValid = TechnicalParametersTabSchema.isValidSync(resource);
    const isConsumptionTabValid = ConsumptionTabSchema.isValidSync(resource);
    const isProductionTabValid = ProductionTabSchema.isValidSync(resource);
    const isOperationTabValid = OperationTabSchema.isValidSync(resource);

    if (resource === null) {
        return <>Invalid resource id</>;
    }

    if (resource === undefined) {
        return <LoadingComponent />;
    }

    const enableSave = (): boolean =>
        isFieldValid(
            GeneralTabSchema,
            [
                SchemaConstants.externalID,
                SchemaConstants.consumption,
                SchemaConstants.consumesEnergy,
                SchemaConstants.production,
                SchemaConstants.producesEnergy,
                SchemaConstants.technicalResourceType,
            ],
            {
                consumesEnergy: resource.consumesEnergy,
                producesEnergy: resource.producesEnergy,
                externalID: resource.externalID,
                technicalResourceType: resource.technicalResourceType,
                consumption: {
                    externalID: resource.consumption?.externalID,
                },
                production: {
                    externalID: resource.production?.externalID,
                },
            },
        ).valid;

    const selectTab = (e: any): void => {
        let { selected } = e;
        if (selected === TechnicalResourcesPage.CONSUMPTION && !resource.consumesEnergy) {
            // we have two options here, either he clicked on production or operation
            if (!resource.producesEnergy) {
                // for sure he clicked on operation
                selected = TechnicalResourcesPage.OPERATION;
            } else {
                selected = TechnicalResourcesPage.PRODUCTION;
            }
        } else if (selected === TechnicalResourcesPage.PRODUCTION) {
            if (!resource.producesEnergy && !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.PARAMETERS;
            } else if (!resource.producesEnergy || !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.OPERATION;
            }
        } else if (selected === TechnicalResourcesPage.OPERATION) {
            if (!resource.producesEnergy && !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.DOWNTIME;
            } else if (!resource.producesEnergy || !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.PARAMETERS;
            }
        } else if (selected === TechnicalResourcesPage.PARAMETERS) {
            if (!resource.producesEnergy || !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.DOWNTIME;
            }
        } else if (selected === TechnicalResourcesPage.DOWNTIME) {  
            if (!resource.producesEnergy && !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.DOWNTIME;
            } else if (!resource.producesEnergy || !resource.consumesEnergy) {
                selected = TechnicalResourcesPage.UNAVAILABILITY;
            }    
        } 

        switch (selected) {
            case TechnicalResourcesPage.GENERAL:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}`);
                break;
            case TechnicalResourcesPage.CONSUMPTION:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/consumption`);
                break;
            case TechnicalResourcesPage.PRODUCTION:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/production`);
                break;
            case TechnicalResourcesPage.OPERATION:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/operation`);
                break;
            case TechnicalResourcesPage.PARAMETERS:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/parameters`);
                break;
            case TechnicalResourcesPage.DOWNTIME:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/downtime`);
                break;
            case TechnicalResourcesPage.UNAVAILABILITY:
                history.replace(`/controllable/${controllableId}/resources/${technicalId}/unavailability`);
                break;
            default:
                break;
        }
    };

    if (controllableResource === undefined) return <LoadingComponent />;

    const isFullyControllable = controllableResource !== null && !isEmergencyGenerator(controllableResource);

    return (
        <List
            disablePadding
            style={{
                pointerEvents: isSaving ? "none" : "all",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <DetailsViewToolbar
                disabled={!enableSave()}
                onEditClick={
                    EDITING_PAGES.includes(page) && controllableResource?.status !== ControllableResourceStatus.Sent
                        ? toggleEditing
                        : undefined
                }
                onSaveClick={!isSaving ? saveChanges : undefined}
                pageStatus={pageStatus}
                onAddClick={!isSaving ? createTechnicalResource : undefined}
                onCancelClick={!isSaving ? toggleEditing : undefined}
                onRefresh={!isSaving ? forceRefresh : undefined}
                onPreviousClick={currentResourceIndex > 0 ? handlePreviousClick : undefined}
                onNextClick={
                    currentResourceIndex < (allResources ?? []).length - 1 && currentResourceIndex !== -1
                        ? handleNextClick
                        : undefined
                }
                isForGroup={false}
            />

            <TabStrip
                tabPosition={matchesSmallDevice ? "top" : "left"}
                selected={transformPageStringToPageIndex()}
                className={matchesSmallDevice ? classes.mobileScrollableTabs : classes.scrollableTabs}
                onSelect={selectTab}
            >
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isGeneralTabValid}
                            title={t("technical_resources_details.tabs.general")}
                        />
                    }
                >
                    {isSaving ? <LoadingComponent /> : <General pageStatus={pageStatus} />}
                </TabStripTab>
                {!!resource.consumesEnergy && (
                    <TabStripTab
                        title={
                            <CustomTabTitle
                                isValid={isConsumptionTabValid}
                                title={t("technical_resources_details.tabs.consumption")}
                            />
                        }
                    >
                        <MarketLocationSubGrid field={FieldForActions.CONSUMPTION} pageStatus={pageStatus} />
                    </TabStripTab>
                )}
                {!!resource.producesEnergy && (
                    <TabStripTab
                        title={
                            <CustomTabTitle
                                isValid={isProductionTabValid}
                                title={t("technical_resources_details.tabs.production")}
                            />
                        }
                    >
                        <MarketLocationSubGrid field={FieldForActions.PRODUCTION} pageStatus={pageStatus} />
                    </TabStripTab>
                )}
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isOperationTabValid}
                            title={t("technical_resources_details.tabs.operation")}
                        />
                    }
                >
                    <OperationTab pageStatus={pageStatus} />
                </TabStripTab>
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isTechnicalParametersTabValid}
                            title={t("technical_resources_details.tabs.technicalParameters")}
                        />
                    }
                >
                    <TechnicalParametersSubGrid pageStatus={pageStatus} />
                </TabStripTab>
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={<CustomTabTitle isValid title={t("technical_resources_details.tabs.downtime")} />}
                    >
                        <DowntimeTable />
                    </TabStripTab>
                )}                
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={<CustomTabTitle isValid title={t("technical_resources_details.tabs.unavailability")} />}
                    >
                        <UnavailabilityTable />
                    </TabStripTab> 
                )}                 
            </TabStrip>
        </List>
    );
};

export default TechnicalResourceDetailsView;
