import { DateTime, Interval } from "luxon";
import * as yup from "yup";
import { createDateTime } from "ndr-designsystem";
import { isEqual } from "lodash";
import {
    allowedCodes as allowedUnitCodes,
    allowedControlGroupCodes as allowedControlGroupUnitCodes,
    Unit
} from "../api/fixed/Unit";
import { allowedCodes as allowedEnergySourceCodes, EnergySource } from "../api/fixed/ControllableResource/EnergySource";
import { allowedCodes as allowedCompensationTypeCodes } from "../api/fixed/ControllableResource/CompensationType";
import { allowedCodes as allowedActivationTypeCodes } from "../api/fixed/ControllableResource/ActivationType";
import { allowedCodes as allowedAccountingModelCodes } from "../api/fixed/ControllableResource/AccountingModel";
import { allowedCodes as allowedControlAreaCodes } from "../api/fixed/ControllableResource/ControlArea";
import { allowedCodes as allowedGradientUnitCodes } from "../api/fixed/ControllableResource/GrdientUnit";
import {
    allowedCodes as allowedControllabilityTypeCodes,
    ControllabilityType
} from "../api/fixed/ControllableResource/ControllabilityType";
import { allowedCodes as allowedFixationCodes } from "../api/fixed/Fixation";
import { allowedCodes as allowedTechnicalResourceTypeCodes } from "../api/fixed/TechnicalResource/TechnicalResourceType";
import { allowedCodes as allowedTrancheSizeUnitCodes } from "../api/fixed/Tranche/TrancheSizeUnit";
import { allowedCodes as allowedVoltageLevelCodes } from "../api/fixed/MarketLocation/VoltageLevel";
import { allowedCodes as allowedVoltageTransformationCodes } from "../api/fixed/MarketLocation/VoltageTransformation";
import { allowedCodes as allowedBillingModelCodes } from "../api/fixed/TechnicalResource/BillingModel";
import { TechnicalResource } from "../api/fixed/TechnicalResource/TechnicalResource";
import { allowedCodes as allowedGridElementCodingSchemaCodes } from "../api/fixed/Sensitivity/GridElementCodingScheme";
import { SensitivityData } from "../api/fixed/Sensitivity/SensitivityData";
import { allowedCodes as allowedDirectionCodes } from "../api/fixed/Sensitivity/Direction";
import { ControllableResource } from "../api/fixed/ControllableResource/ControllableResource";
import { sameOrGreater } from "./comparers";
import WindPowerCurveElement from "../api/fixed/TechnicalResource/WindPowerCurveElement";
import WindPower from "../api/fixed/TechnicalResource/WindPower";
import { MarketPartner } from "../api/fixed/MarketPartner";
import CostInfo, { allowedBusinessTypeCodes } from "../api/fixed/CostInfo/CostInfo";

export const StringsArraySchema = (): yup.ArraySchema<any> => yup.array().nullable().of(yup.string());
export const EEGUnitCodeSchema = (required: boolean): yup.AnySchema => required ? yup.string().matches(/^E[1-4]\d{6}[A-Za-z\d-]{20}\d{5}$/, "validation:technical_resource_details.eeg_unit_codes_matches").required("validation:required").min(1, "validation:string_min_length") : yup.string().optional().default(undefined);
export const EEGUnitCodesArraySchema = (): yup.ArraySchema<any> => yup.array().nullable().of(EEGUnitCodeSchema(true));
export const MeasurementLocationSchema = (): yup.AnySchema => yup.string().required("validation:required").matches(/DE[0-9]*/, "validation:technical_resource_details.measurement_location_externalID").min(33, "validation:technical_resource_details.measurement_location_externalID").max(33, "validation:technical_resource_details.measurement_location_externalID");
export const MeasurementLocationsSchema = (): yup.ArraySchema<any> => yup.array().of(MeasurementLocationSchema()).required("validation:required");
export const GridOperatorsSchema = (marketPartnerIds: string[]): yup.AnySchema => yup.string().oneOf(marketPartnerIds, "validation:one_of_market_partners");
export const SimpleStringSchema = (required: boolean): yup.AnySchema => required ? yup.string().required("validation:required").min(1, "validation:string_min_length") : yup.string().optional().default(undefined);
export const NumberParameterSchema = (required: boolean): yup.NumberSchema => {
    let base = yup.number();

    if (required)
        base = base.required("validation:required");
    else
        base = base.optional().default(undefined);

    return base.max(999999.999, "validation:max_value_MAW");
};

export const WindPowerCurveSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    elements: yup.array().required("validation:required")
        .test("No undefined", "validation:technical_resource_details.no_undefined", (values?: WindPowerCurveElement[]) => (values ?? []).every(v => v.value !== undefined))
        .test("Increasing values", "validation:technical_resource_details.increasing_values", (values?: WindPowerCurveElement[]) => {
            let valuesToCheck = values;
            if (!valuesToCheck)
                valuesToCheck = []
            return valuesToCheck.map(v => v.value).every(sameOrGreater);
        })

})

export const WindPowerCurveElementSchema = (allElements: WindPowerCurveElement[], elementIndex: number, maxValue: number): yup.NumberSchema => yup.number()
    .test("No undefined", "validation:technical_resource_details.no_undefined", (val?: number) => val !== undefined)
    .test("Less than net rated capacity", "validation:technical_resource_details.less_than_net_rated_capacity", (val?: number) => (val ?? 0) <= maxValue)
    .test("Increasing values", "validation:technical_resource_details.increasing_values", (val?: number) => [...allElements.slice(0, elementIndex).map(v => v.value), val ?? 0].every(sameOrGreater))
export const ParameterWithUnitSchema = (unit: Unit | undefined): yup.NumberSchema => {
    let levelSchema = yup.number().typeError("validation:number").required("validation:required");

    if (unit && unit.code === "MAW") {
        levelSchema = levelSchema.max(999999.999, "validation:max_value_MAW");
    } else if (unit && unit.code === "P1") {
        levelSchema = levelSchema.integer("validation:integer").max(100, "validation:max_value_percent");
    }

    return levelSchema;
};

export const CGGeneralTabSchema = (marketPartnerIds: any[], currentTenantMarketPartner: MarketPartner[] | undefined, otherExternalIDs: string[]): yup.ObjectSchema<any> => yup.object().shape({
    externalID: yup.string().nullable().required("validation:required").matches(/^B[A-Z\d]{9}\d$/, "validation:control_groups_details.externalID_match_fail")
        .test("Not duplicate", "validation:external_id_not_duplicate", value => !otherExternalIDs.includes(value ?? "")),
    name: yup.string().nullable().max(35, "validation:name_maximum_length").matches(/^[A-Z0-9\-+\\_]{0,35}$/, "validation:control_groups_details.name_pattern_fail"),
    affectedGridOperators: yup.array().nullable().required("validation:required").of(GridOperatorsSchema(marketPartnerIds))
        .test('Current tenant market partner', 'validation:controllable_resource_details.affected_grid_operators_current_tenant', (value) => currentTenantMarketPartner != null && value != null && value.length > 0 && currentTenantMarketPartner.find(t => t.inventoryItemId === value[0]) !== undefined)
        .min(2, "validation:controllable_resource_details.affected_grid_operators_min_length").max(6, "validation:controllable_resource_details.affected_grid_operators_max_length"),
    additionalAffectedGridOperators: yup.array().nullable().nullable().of(GridOperatorsSchema(marketPartnerIds)).min(0).max(100, "validation:controllable_resource_details.affected_grid_operators_max_length")
});


export const CGControllabilityTabSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    fixation: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedFixationCodes)
    }),
    controllabilityType: yup.number().typeError("validation:number").required("validation:required").oneOf(allowedControllabilityTypeCodes),
    unit: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedControlGroupUnitCodes)
    }),
    levels: yup.array().nullable().when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.LEVELS) {
            return yup.array().of(ParameterWithUnitSchema(unit)).min(2, "validation:control_groups_details.levels_array_min_elements").max(10, "validation:control_groups_details.levels_array_max_elements").required("validation:required");
        }
        return yup.array().optional().default(undefined);
    }),
    stepLength: yup.number().typeError("validation:number").when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    minSteps: yup.number().typeError("validation:number").when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    maxSteps: yup.number().typeError("validation:number").when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    minimumMinutesRequiredBeforeActivation: yup.number().min(0, "validation:control_groups_details.minimumMinutesRequiredBeforeActivation_min").max(5, "validation:control_groups_details.minimumMinutesRequiredBeforeActivation_max").typeError("validation:number").required("validation:required").integer("validation:integer")
});

export const CRGeneralTabSchema = (marketPartnerIds: any[], currentTenantMarketPartner: MarketPartner[] | undefined, otherExternalIDs: string[]): yup.ObjectSchema<any> => yup.object().shape({
    externalID: yup.string().nullable().required("validation:required")
        .matches(/^C[A-Z\d]{9}\d$/, "validation:controllable_resource_details.externalID_match_fail")
        .test("Not duplicate", "validation:external_id_not_duplicate", value => !otherExternalIDs.includes(value ?? "")),
    name: yup.string().nullable().max(35, "validation:controllable_resource_details.name_maximum_length").nullable().matches(/^[A-Z0-9\-+\\_]{0,35}$/, "validation:controllable_resource_details.name_pattern_fail"),
    affectedGridOperatorIds: yup.array().nullable().required("validation:required").of(GridOperatorsSchema(marketPartnerIds))
        .test('Current tenant market partner', 'validation:controllable_resource_details.affected_grid_operators_current_tenant', (value) => currentTenantMarketPartner != null && value != null && value.length > 0 && currentTenantMarketPartner.find(t => t.inventoryItemId === value[0]) !== undefined)
        .min(2, "validation:controllable_resource_details.affected_grid_operators_min_length").max(6, "validation:controllable_resource_details.affected_grid_operators_max_length"),
    additionalAffectedGridOperatorIds: yup.array().nullable().of(GridOperatorsSchema(marketPartnerIds)).min(0).max(100, "validation:controllable_resource_details.affected_grid_operators_max_length"),
    operationsManagerId: GridOperatorsSchema(marketPartnerIds).nullable(),
    energySource: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedEnergySourceCodes)
    }),
    controlArea: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedControlAreaCodes)
    }),
    compensationType: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedCompensationTypeCodes)
    }),
    accountingModel: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedAccountingModelCodes)
    })
});


export const CRTechnicalParametersSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    minPower: NumberParameterSchema(false),
    minOperatingTime: NumberParameterSchema(false).integer('validation:integer'),
    minDownTime: NumberParameterSchema(false).integer('validation:integer'),
    rampupTimeCold: NumberParameterSchema(false).integer('validation:integer'),
    rampupTimeHot: NumberParameterSchema(false).integer('validation:integer'),
    startupTimeCold: NumberParameterSchema(false).integer('validation:integer'),
    startupTimeHot: NumberParameterSchema(false).integer('validation:integer'),
    shutdownTime: NumberParameterSchema(false).integer('validation:integer'),
    ratedCapacityGradientUnit: yup.object().nullable().default(undefined).shape({
        code: yup.string().nullable().optional().oneOf(allowedGradientUnitCodes)
    }),
    ratedCapacityGradient: yup.number().typeError("validation:number").nullable().default(undefined).when("ratedCapacityGradientUnit", (ratedCapacityGradientUnit) => {
        if (ratedCapacityGradientUnit) {
            if (ratedCapacityGradientUnit.code === "Z01") {
                return NumberParameterSchema(true).max(100, "validation:max_value_percent");
            }
            return NumberParameterSchema(true);
        }
        return NumberParameterSchema(false);
    }),
    ratedCapacityGradientBaseSize: yup.number().typeError("validation:number").nullable().required().default(undefined).when("ratedCapacityGradientUnit", (ratedCapacityGradientUnit) => {
        if (ratedCapacityGradientUnit && ratedCapacityGradientUnit.code === "Z01")
            return NumberParameterSchema(true);
        return NumberParameterSchema(false);
    }),
    minCapacityGradientUnit: yup.object().nullable().default(undefined).shape({
        code: yup.string().optional().oneOf(allowedGradientUnitCodes)
    }),
    minCapacityGradient: yup.number().typeError("validation:number").nullable().required().default(undefined).when("minCapacityGradientUnit", (minCapacityGradientUnit) => {
        if (minCapacityGradientUnit) {
            if (minCapacityGradientUnit.code === "Z01") {
                return NumberParameterSchema(true).max(100, "validation:max_value_percent");
            }
            return NumberParameterSchema(true);
        }
        return NumberParameterSchema(false);
    }),
    minCapacityGradientBaseSize: yup.number().typeError("validation:number").when("minCapacityGradientUnit", (minCapacityGradientUnit) => {
        if (minCapacityGradientUnit && minCapacityGradientUnit.code === "Z01")
            return NumberParameterSchema(true);
        return NumberParameterSchema(false);
    })
})

export const CRControllabilitySchema = (): yup.ObjectSchema<any> => yup.object().shape({
    isTolerationCase: yup.boolean().optional().default(false),
    activationType: yup.object().nullable().when("isTolerationCase", isTolerationCase => {
        if (!isTolerationCase) {
            return yup.object().nullable().default(undefined).required("validation:required").shape({
                code: yup.string().required("validation:required").oneOf(allowedActivationTypeCodes)
            });
        }
        return yup.object().nullable().optional().default(undefined);
    }),
    processingTimeEiv: yup.number().typeError("validation:number").when("isTolerationCase", isTolerationCase => {
        if (!isTolerationCase) {
            return yup.number().typeError("validation:number").required("validation:required").integer("validation:integer");
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    fixation: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedFixationCodes)
    }),
    controllabilityType: yup.number().typeError("validation:number").required("validation:required").oneOf(allowedControllabilityTypeCodes),
    unit: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedUnitCodes)
    }),
    levels: yup.array().nullable().when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.LEVELS) {
            return yup.array().required("validation:required").of(ParameterWithUnitSchema(unit)).min(2, "validation:control_groups_details.levels_array_min_elements").max(10, "validation:control_groups_details.levels_array_max_elements");
        }
        return yup.array().optional().default(undefined);
    }),
    stepLength: yup.number().typeError("validation:number").when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    minSteps: yup.number().typeError("validation:number").when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    }),
    maxSteps: yup.number().when(["controllabilityType", "unit"], (controllabilityType, unit) => {
        if (controllabilityType === ControllabilityType.STEPS) {
            return ParameterWithUnitSchema(unit);
        }
        return yup.number().typeError("validation:number").optional().default(undefined);
    })
});

export const TRGeneralTabSchema = (marketPartnerIds: any[], isEEG: boolean, otherExternalIDs: string[]): yup.ObjectSchema<any> => yup.object().shape({
    externalID: yup.string().nullable().matches(/^D[A-Z\d]{9}\d$/, "validation:technical_resource_details.externalID_match_fail").required("validation:required")
        .test("Not duplicate", "validation:external_id_not_duplicate", value => !otherExternalIDs.includes(value ?? "")),
    name: yup.string().nullable().max(35, "validation:technical_resource_details.name_maximum_length").matches(/^[A-Z0-9\-+\\_]{0,35}$/, "validation:technical_resource_details.name_pattern_fail"),
    marketMasterDataRegistryId: yup.string().nullable().matches(/[A-Z0-9-+_]*/, "validation:alphanumeric").max(15, "validation:technical_resource_details.market_master_maximum_length"),
    technicalResourceType: yup.object().nullable().default(undefined).required('validation:required').shape({
        code: yup.string().required("validation:required").oneOf(allowedTechnicalResourceTypeCodes)
    }),
    powerPlantCode: yup.string().nullable().matches(/^[A-Z0-9-+_]*$/, "validation:alphanumeric").min(16, "validation:technical_resource_details.power_plant_code_length").max(16, "validation:technical_resource_details.power_plant_code_length").transform((_, val) => !val || val === '' ? null : val),
    assignedStorages: StringsArraySchema().max(33, "validation:technical_resource_details.assigned_storages_max_length"),
    eegUnitCodes: EEGUnitCodesArraySchema().min(isEEG ? 1 : 0).max(33, "validation:technical_resource_details.eeg_unit_codes_max_length"),
    billingModel: yup.object().nullable().default(undefined).required("validation:required").shape({
        code: yup.string().required("validation:required").oneOf(allowedBillingModelCodes)
    }),
    operatorId: GridOperatorsSchema(marketPartnerIds).nullable(),
    producesEnergy: yup.boolean().nullable()
        .required("validation:required")
        .test('Either produces or consumes', 'validation:technical_resource_details.either_produces_consumes', (value, context) => context.parent.consumesEnergy || value),
    consumesEnergy: yup.boolean().nullable()
        .required("validation:required")
        .test('Either produces or consumes', 'validation:technical_resource_details.either_produces_consumes', (value, context) => context.parent.producesEnergy || value),
    production: yup.object().nullable().when('producesEnergy', {
        is: (producesEnergy: boolean) => producesEnergy,
        then: yup.object().shape({
            externalID: yup.string().nullable().required("validation:required").matches(/\d{11}/, "validation:numbers").min(11, "validation:technical_resource_details.external_id_market_location").max(11, "validation:technical_resource_details.external_id_market_location"),
        })
    }),
    consumption: yup.object().nullable().when('consumesEnergy', {
        is: (consumesEnergy: boolean) => consumesEnergy,
        then: yup.object().shape({
            externalID: yup.string().nullable().required("validation:required").matches(/\d{11}/, "validation:numbers").min(11, "validation:technical_resource_details.external_id_market_location").max(11, "validation:technical_resource_details.external_id_market_location"),
        })
    })
});

export const TRTechnicalParametersSchema = (energySource: EnergySource | undefined, isSSE: boolean, missingValueForDirection: boolean | undefined, missingValueForIncline: boolean | undefined): yup.ObjectSchema<any> => yup.object().shape({
    productionNetRatedCapacity: NumberParameterSchema(true),
    productionNetBottleneckCapacity: NumberParameterSchema(false),
    consumptionNetRatedCapacity: NumberParameterSchema(isSSE),
    consumptionNetBottleneckCapacity: NumberParameterSchema(isSSE),
    grossRatedCapacity: NumberParameterSchema(true),
    accumulatedInverterPower: NumberParameterSchema(energySource !== undefined && energySource.code === "B16"),
    plantType: SimpleStringSchema(energySource !== undefined && ["B18", "B19"].includes(energySource.code ?? "")).default(undefined),
    windPowerCurve: ["B18", "B19"].includes(energySource?.code ?? "") ? WindPowerCurveSchema()
            .test("Less than net rated capacity", "validation:technical_resource_details.less_than_net_rated_capacity", (val: WindPower | undefined, context) => (val?.elements ?? []).every(v => (v.value ?? 0) <= context.parent.productionNetRatedCapacity * 1000))
        : yup.object().nullable().optional(),
    hubHeight: NumberParameterSchema(energySource !== undefined && ["B18", "B19"].includes(energySource.code ?? "")),
    longitude: NumberParameterSchema(true),
    latitude: NumberParameterSchema(true),
    efficiencyOfEnergyStorage: NumberParameterSchema(isSSE),
    usableEnergyContentOfEnergyStorage: NumberParameterSchema(isSSE),
    maxEffectiveStoringCapacity: NumberParameterSchema(isSSE),
    maxEffectiveRetrievingCapacity: NumberParameterSchema(isSSE),
    netRatedCapacityWindSpeed: ["B18", "B19"].includes(energySource?.code ?? "") ? yup.number().required("validation:required") : yup.number(),
    shutdownWindSpeed: ["B18", "B19"].includes(energySource?.code ?? "") ? yup.number().required("validation:required")
        .test("Greater than net rated capacity",
            "validation:technical_resource_details.greater_than_net_rated_capacity_wind_speed",
            (val: number | undefined, context) => (val ?? 0) > context.parent.netRatedCapacityWindSpeed) : yup.number(),
    direction: NumberParameterSchema(!missingValueForDirection && energySource !== undefined && ["B16"].includes(energySource.code ?? "")).min(0, "validation:technical_resource_details.direction_min").max(360, "validation:technical_resource_details.direction_max"),
    incline: NumberParameterSchema(!missingValueForIncline && energySource !== undefined && ["B16"].includes(energySource.code ?? "")).min(0, "validation:technical_resource_details.incline_min").max(90, "validation:technical_resource_details.incline_max")
});

export const TrancheSchema = (marketPartnerIds: any[]): yup.ObjectSchema<any> => yup.object().shape({
    externalID: yup.string().nullable().required("validation:required").min(11, "validation:technical_resource_details.external_id_tranches").max(11, "validation:technical_resource_details.external_id_tranches"),
    accountingGroup: yup.string().nullable().required().default(undefined).min(16, "validation:technical_resource_details.accounting_group_length").min(16, "validation:technical_resource_details.accounting_group_length"),
    supplierId: GridOperatorsSchema(marketPartnerIds).required("validation:required").nullable(),
    sizeUnit: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(allowedTrancheSizeUnitCodes)
    }),
    size: yup.number().typeError("validation:number").optional().default(undefined)
});

export const MarketLocationSchema = (marketPartnerIds: any[]): yup.ObjectSchema<any> => yup.object().shape({
    tranches: yup.array().nullable().of(TrancheSchema(marketPartnerIds)),
    accountingGroup: yup.string().when("tranches", tranches => {
        if (!tranches || tranches.length === 0) {
            return yup.string().nullable().required("validation:required").min(16, "validation:technical_resource_details.accounting_group_length").max(16, "validation:technical_resource_details.accounting_group_length");
        }
        return yup.string().nullable().optional().default(undefined);
    }),
    supplierId: yup.string().when("tranches", tranches => {
        if (!tranches || tranches.length === 0) {
            return GridOperatorsSchema(marketPartnerIds).required("validation:required");
        }
        return yup.string().nullable().optional().default(undefined);
    }),
    voltageLevel: yup.object().nullable().required("validation:required").default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(allowedVoltageLevelCodes)
    }),
    voltageTransformation: yup.object().nullable().default(undefined).shape({
        code: yup.string().oneOf(allowedVoltageTransformationCodes)
    }),
    measurementLocations: MeasurementLocationsSchema().min(1, "validation:technical_resource_details.measurement_locations_min_length").max(33, "validation:technical_resource_details.measurement_locations")
});

export const TRProductionTabSchema = (marketPartnerIds: any[], producesEnergy: boolean): yup.ObjectSchema<any> => yup.object().shape({
    production: producesEnergy ? MarketLocationSchema(marketPartnerIds) : yup.object().nullable().optional().default(undefined)
});

export const TRConsumptionTabSchema = (marketPartnerIds: any[], consumesEnergy: boolean): yup.ObjectSchema<any> => yup.object().shape({
    consumption: consumesEnergy ? MarketLocationSchema(marketPartnerIds) : yup.object().nullable().optional().default(undefined)
});

export const TROperationSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    preliminaryDecommissioningDate: yup.date().nullable().optional().default(undefined),
    finalDecommissioningDate: yup.date().nullable().optional().default(undefined)
});


export const TRSchema = (marketPartnerIds: any[], energySource: EnergySource | undefined, isSSE: boolean, consumesEnergy: boolean, producesEnergy: boolean, isEEG: boolean, missingValueForDirection: boolean | undefined, missingValueForIncline: boolean | undefined, otherExternalIDs: string[]): yup.ObjectSchema<any> => TRGeneralTabSchema(marketPartnerIds, isEEG, otherExternalIDs)
    .concat(TRTechnicalParametersSchema(energySource, isSSE, missingValueForDirection, missingValueForIncline))
    .concat(TRProductionTabSchema(marketPartnerIds, producesEnergy))
    .concat(TRConsumptionTabSchema(marketPartnerIds, consumesEnergy))
    .concat(TROperationSchema());


export const CRSchema = (marketPartnerIds: any[], currentTenantMarketPartner: MarketPartner[] | undefined, otherCRsExternalID: string[], otherTRsExternalID: string[]): yup.ObjectSchema<any> => CRGeneralTabSchema(marketPartnerIds, currentTenantMarketPartner, otherCRsExternalID)
    .concat(CRTechnicalParametersSchema())
    .concat(CRControllabilitySchema())
    .concat(yup.object().test("technicalResources", "Technical resources invalid", (resource: any) => {
        if (resource.technicalResources) {
            return resource.technicalResources.every((item: TechnicalResource) =>
                TRSchema(marketPartnerIds,
                    resource.energySource,
                    item.technicalResourceType?.code === "SSE",
                    item.consumesEnergy ?? false,
                    item.producesEnergy ?? false,
                    resource.compensationType?.code === "Z01",
                    item.missingValueForDirection,
                    item.missingValueForIncline,
                    otherTRsExternalID
                ).isValidSync(item)
            );
        }
        return false;
    }));

export const CRTRTableSchema = (): yup.ArraySchema<any> => yup.array()
    .test('CRs are at least one', 'validation:controllable_resource_details.at_least_one_tr', (value) => value != null && value.length >= 1)

export const CGCRTableSchema = (): yup.ArraySchema<any> => yup.array()
    .test('CRs are in toleration case', 'validation:control_groups_details.included_controllable_resources_toleration_case', (value) => value != null && value.every(cr => cr.isTolerationCase === true))
    .test('CRs have successful dispatch', 'validation:control_groups_details.included_controllable_resources_successful_dispatch', (value) => value != null && value.every(cr => cr.hasSuccessfulDispatch === true))
    .test('CRs are at least two', 'validation:control_groups_details.at_least_two_crs', (value) => value != null && value.length >= 2)

export const CGSchema = (marketPartnerIds: any[], controllableResources: ControllableResource[], currentTenantMarketPartner: MarketPartner[] | undefined, otherExternalIDs: string[]): yup.ObjectSchema<any> => CGGeneralTabSchema(marketPartnerIds, currentTenantMarketPartner, otherExternalIDs)
    .concat(CGControllabilityTabSchema())
    .test('CRs are in toleration case', 'validation:control_groups_details.included_controllable_resources_toleration_case', (value) => value != null && controllableResources.every(cr => cr.isTolerationCase === true))
    .test('CRs have successful dispatch', 'validation:control_groups_details.included_controllable_resources_successful_dispatch', () => controllableResources.every(cr => cr.hasSuccessfulDispatch === true))
    .test('CRs are at least two', 'validation:control_groups_details.at_least_two_crs', () => controllableResources.length >= 2)

export const MPSchema = (otherMPs: MarketPartner[]): yup.ObjectSchema<any> => yup.object().shape({
    enteredCode: yup
        .string()
        .matches(/\d{13}/, 'validation:add_market_partner.entered_code_length')
        .required('validation:add_market_partner.entered_code_required')
        .test("Unique encoding", "validation:add_market_partner.unique", (val, ctx) => otherMPs.find(mp => mp.encoding?.code === ctx.parent.selectedCode && mp.code === val) === undefined),
    selectedCode: yup
        .string()
        .required('validation:required')
        .test("Unique encoding", "validation:add_market_partner.unique", (val, ctx) => otherMPs.find(mp => mp.encoding?.code === val && mp.code === ctx.parent.enteredCode) === undefined),
    name: yup
        .string()
})

export const GridElementSensitivitiesSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    gridElementCodingScheme: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(allowedGridElementCodingSchemaCodes, "")
    }),
    gridElementCode: yup.string().required("validation:required"),
    quantity: yup.number().nullable().typeError("validation:number").required("validation:required").min(0).max(100),
})

export const CostInfoSchema = (otherCostInfo: CostInfo[], currentCostInfoObject: CostInfo | null): yup.ObjectSchema<any> => yup.object().shape({
    status: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(currentCostInfoObject?.allowedStatusCodes ?? [], "")
    }),
    direction: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(currentCostInfoObject?.allowedDirectionCodes ?? [], "")
    }),
    businessType: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(allowedBusinessTypeCodes, "")
    }),
    value: yup.number(),
    from: yup.string().nullable()
        .required("validation:required")
        .test('Before end date', 'validation:add_sensitivity.start_date_before_end_date', (value, context) => {
            if (context.parent.to == null || value == null) return true;
            const convertedFrom = createDateTime(value);
            const convertedTo = createDateTime(context.parent.to);

            return convertedFrom < convertedTo;
        })
        .test('Overlapping test', 'validation:add_sensitivity.no_overlap', (value, context) => {
            if (value == null || context.parent.to == null) return true;
            // @ts-ignore value is DateTime
            const convertedFrom: DateTime = createDateTime(value);
            const convertedTo: DateTime = createDateTime(context.parent.to!)
            const currentInterval = Interval.fromDateTimes(convertedFrom, convertedTo);
            return otherCostInfo.every(costInfo => !isEqual(costInfo.businessType, currentCostInfoObject?.businessType) || costInfo.id === currentCostInfoObject?.id || !currentInterval.overlaps(Interval.fromDateTimes(costInfo.from!, costInfo.to!)));
        }),
    to: yup.string().nullable().required('validation:required')
        .test('After start date', 'validation:add_sensitivity.end_date_after_start_date', (value, context) => {
            if (context.parent.from == null || value == null) return true;
            const convertedFrom = createDateTime(context.parent.from);
            const convertedTo = createDateTime(value);

            return convertedTo > convertedFrom;
        })
        .test('Overlapping test', 'validation:add_sensitivity.no_overlap', (value, context) => {
            if (value == null || context.parent.from == null) return true;

            const convertedFrom: DateTime = createDateTime(context.parent.from!)
            // @ts-ignore value is DateTime
            const convertedTo: DateTime = createDateTime(value)
            const currentInterval = Interval.fromDateTimes(convertedFrom, convertedTo);
            return otherCostInfo.every(costInfo => !isEqual(costInfo.businessType, currentCostInfoObject?.businessType) || costInfo.id === currentCostInfoObject?.id || !currentInterval.overlaps(Interval.fromDateTimes(costInfo.from!, costInfo.to!)));
        })
});

export const SensitivitySchema = (otherSensitivities: SensitivityData[]): yup.ObjectSchema<any> => yup.object().shape({
    gridElementSensitivities: yup.array().of(GridElementSensitivitiesSchema()).test('Maximum 100 quantity', 'validation:add_sensitivity.quantity_exactly_100', val => val != null && val.reduce((v1, v2) => v1 + (v2.quantity ?? 0), 0) === 100),
    direction: yup.object().nullable().default(undefined).shape({
        code: yup.string().required("validation:required").oneOf(allowedDirectionCodes, "")
    }),
    intervalStart: yup.string().nullable()
        .required("validation:required")
        .test('Before end date', 'validation:add_sensitivity.start_date_before_end_date', (value, context) => {
            if (context.parent.intervalEnd == null || value == null) return true;
            const convertedStart = createDateTime(value);
            const convertedEnd = createDateTime(context.parent.intervalEnd);

            return convertedStart < convertedEnd;
        })
        .test('Overlapping test', 'validation:add_sensitivity.no_overlap', (value, context) => {
            if (value == null || context.parent.intervalEnd == null) return true;
            // @ts-ignore value is DateTime
            const intervalStart: DateTime = createDateTime(value);
            const intervalEnd: DateTime = createDateTime(context.parent.intervalEnd!)
            const currentInterval = Interval.fromDateTimes(intervalStart, intervalEnd);
            return otherSensitivities.every(sensitivity => !currentInterval.overlaps(sensitivity.period!));
        }),
    intervalEnd: yup.string().nullable().required('validation:required')
        .test('After start date', 'validation:add_sensitivity.end_date_after_start_date', (value, context) => {
            if (context.parent.intervalStart == null || value == null) return true;
            const convertedStart = createDateTime(context.parent.intervalStart);
            const convertedEnd = createDateTime(value);

            return convertedEnd > convertedStart;
        })
        // .test('Position test', 'validation:add_sensitivity.end_date_not_empty_first_element', value =>
        //     otherSensitivities.length === 0 && value !== undefined)
        .test('Overlapping test', 'validation:add_sensitivity.no_overlap', (value, context) => {
            if (value == null || context.parent.intervalStart == null) return true;

            const intervalStart: DateTime = createDateTime(context.parent.intervalStart!)
            // @ts-ignore value is DateTime
            const intervalEnd: DateTime = createDateTime(value)
            const currentInterval = Interval.fromDateTimes(intervalStart, intervalEnd);
            return otherSensitivities.every(sensitivity => !currentInterval.overlaps(sensitivity.period!));            
        })
})
 
export const RequestingGridOperatorSchema = (marketPartnerIds: any[]): yup.ObjectSchema<any> =>
    yup.object().shape({
        accountingGroup: yup
            .string()
            .required("validation:add_accounting_group_balancing_schedule.accounting_group")
            .min(16, "validation:technical_resource_details.accounting_group_length")
            .max(16, "validation:technical_resource_details.accounting_group_length"),
        marketPartner: yup.object().shape({
            internalId: yup
                .string()
                .required("validation:add_accounting_group_balancing_schedule.market_partner")
                .oneOf(marketPartnerIds, "validation:one_of_market_partners"),
            name: yup.string().nullable().optional(),
        }),
    });

export const AccountingGroupBalancingSchedulesSchema = (marketPartnerIds: any[]): yup.ObjectSchema<any> => yup.object().shape({
    controllableResource: yup.object().required("validation:required").shape({
        externalId: yup.string().matches(/^C[A-Z\d]{9}\d$/, "validation:controllable_resource_details.externalID_match_fail").required("validation:required"),
    }),    
    requestingGridOperators: yup.array().of(RequestingGridOperatorSchema(marketPartnerIds)),
});

export const IndvidualQuotasInfoSchema = (marketPartnerIds: any[]): yup.ObjectSchema<any> =>
    yup.object().shape({
        value: yup
            .string()
            .required("validation:required")
            .matches(/^(\d+([.,]\d+)?)?$/, "validation:numbers")
            .test(
                'is-between-0-and-100',
                "validation:max_value_percent",
                (value) => {
                  if (!value) return false; 
                  const numValue = parseFloat(value.replace(',', '.'));
                  return numValue >= 0 && numValue <= 100;
                }
            ),             
        accountingGroupBalancingSchedule: yup
            .string()
            .required("validation:required")
            .min(16, "validation:technical_resource_details.accounting_group_length")
            .max(16, "validation:technical_resource_details.accounting_group_length"),
        supplierId: GridOperatorsSchema(marketPartnerIds).required("validation:required"),
    }
);
export const DeactivationSchema = (): yup.ObjectSchema<any> => yup.object().shape({
    existenceEnd: yup.date().nullable().required("validation:required")
});
    
 



