import React, { ReactElement, useEffect, useMemo, useRef } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import { GridCellProps, GridFilterCellProps } from "@progress/kendo-react-grid";
import { Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { GridDataState } from "ndr-designsystem/dist/utils";
import { isEqual } from "lodash";
import DropdownFilterCell from "Components/DropdownFilterCell";
import DataSource from "api/dtos/PlanningData/DataSource";
import PlanningDataSentIndicator from "features/controllableResources/list/columns/PlanningDataSentIndicator";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {
    allPlanningDataSelector, setAllPlanningData,
    setSendingStatusPlanningData,
    setShowProductionData
} from "../../../timeseries/planningData/store/store";
import DateCell from "../../../../Components/DateCell";
import {
    fetchPlanningDataByResource as fetchTimeSeriesByControllableResourceId
} from "../../../timeseries/planningData/store/thunks";
import { TimeSeriesButtonsCount, TimeseriesPageSize } from "../../../../utils";

const PlanningDataTable = (): ReactElement => {
    const allPlanningData = useAppSelector(allPlanningDataSelector, (left, right) => left?.length === right?.length);    
    const { isNextFetchingPossible, isPreviousFetchingPossible } = useAppSelector(state => state.planningData)
    const { currentResource } = useAppSelector(state => state.controllableResources);
    const history = useHistory();
    const oldState = useRef<GridDataState | undefined>(undefined)
    const dispatch = useAppDispatch();  
    const { t } = useTranslation('tables');

    const dataSourceStatuses = Object.values(DataSource).filter(value => typeof value === 'string').map(v => v.toString())

    useEffect(() => {
        dispatch(setShowProductionData(true));
        if (allPlanningData && isPreviousFetchingPossible)
            dispatch(setAllPlanningData(undefined))
    }, [dispatch])

    const fetchPlanningData = async (take: number): Promise<void> => {
        if (currentResource != null) {
            await dispatch(fetchTimeSeriesByControllableResourceId({ id: currentResource.inventoryItemId!, take }));
        }
    }

    const columns = useMemo(
        () => [
            {
                name: "date",
                title: t("timeSeries.date"),
                customCell: (props: GridCellProps) => (
                    <DateCell {...props} fieldPath="date" displayFormat={DateTime.DATE_SHORT} />
                ),
            },
            {
                name: "numberOfTimeSeries",
                title: t("timeSeries.no_contained_resources"),
            },
            {
                name: "dataSource",
                title: t("timeSeries.type"),
                customCell: (props: GridCellProps): ReactElement => (
                    <TranslatedApiValueCell
                        value={props.dataItem?.dataSource}
                        namespace="tables:timeSeries:dataSource"
                    />
                ),
                filterCell: (props: GridFilterCellProps) => (
                    <DropdownFilterCell
                        {...props}
                        namespace="tables:timeSeries:dataSource"
                        data={dataSourceStatuses}
                        defaultItem="All"
                    />
                ),
                filterable: true,
            },
            {
                title: t("controllable_resources_details.sensitivities.view_status"),
                customCell: (props: GridCellProps): ReactElement => (
                    <td>
                        <Box display="flex" justifyContent="space-between" alignItems="center" px={2} py={1}>
                            <Button
                                color="secondary"
                                onClick={() => dispatch(setSendingStatusPlanningData(props.dataItem.internalID))}
                            >
                                {t("controllable_resources_details.sensitivities.view_status_button")}
                            </Button>
                            <PlanningDataSentIndicator sentToConnectPlusStatus={props.dataItem.sentToConnectPlusStatus}/>
                        </Box>
                    </td>
                ),
            },
        ],
        [t, dataSourceStatuses, dispatch],
    );

    return (
        <GridField
            filterable
            processData
            noRecordsText={t("no_records_text")}
            defaultOpen
            pageable={{
                info: false,
                buttonCount: TimeSeriesButtonsCount,
            }}
            take={TimeseriesPageSize}
            addStateToURL
            onDataStateChange={async state => {
                if (!state) return;
                const pageNumber = ((state.skip ?? 0) + TimeseriesPageSize) / TimeseriesPageSize;

                if (((pageNumber === 1 && allPlanningData === undefined) || pageNumber % TimeSeriesButtonsCount === 0) && isNextFetchingPossible && !isEqual(oldState.current, state)) {
                    oldState.current = state;
                    await fetchPlanningData((state.take ?? 0) * 5);
                }
            }}
            dataItemKey="internalID"
            values={allPlanningData}
            onRowClick={event => history.push(`/controllable/${currentResource?.externalID}/planning-data/${event.dataItem.internalID}`)}
            gridFields={columns}
        />
    );
}

export default PlanningDataTable
