import React, { ReactElement, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import { useHistory, useParams } from "react-router-dom";
import { GridCellProps } from "@progress/kendo-react-grid";
import { GridField, TranslatedApiValueCell } from "ndr-designsystem";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "app/hooks";
import DateCell from "Components/DateCell";
import { fetchUnavailabilitiesByTechnicalResource } from "./store/thunks";

interface RouteParams {
    controllableId: string;
    technicalId: string;
    page: string;
}

const UnavailabilityTable = (): ReactElement => {
    const { allUnavailabilities } = useAppSelector(state => state.unavailabilities);
    const { currentResource } = useAppSelector(state => state.technicalResources);
    const { controllableId, technicalId } = useParams<RouteParams>();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const { t } = useTranslation("tables");

    useEffect(() => {
        if (allUnavailabilities === undefined && currentResource != null) {
            dispatch(fetchUnavailabilitiesByTechnicalResource({ id: currentResource.inventoryItemId!, force: false }))
        }
    }, [allUnavailabilities, currentResource, dispatch])

    const columns = useMemo(() => [{
        title: t("unavailability.start"),
        customCell: (props: GridCellProps) => <DateCell
            {...props}
            fieldPath="start"
            displayFormat={DateTime.DATE_SHORT}
        />
    }, {
        title: t("unavailability.end"),
        customCell: (props: GridCellProps) => <DateCell
            {...props}
            fieldPath="end"
            displayFormat={DateTime.DATE_SHORT}
        />
    }, {
        name: "type.name",
        title: t("unavailability.type"),
        customCell: ({ dataItem }: { dataItem: any }) => <TranslatedApiValueCell
            value={dataItem.type?.code}
            namespace="api:unavailability_type"
        />
    }], [t])

    return (
        <GridField
            noRecordsText={t("no_records_text")}
            defaultOpen
            dataItemKey="internalID"
            values={allUnavailabilities}
            onRowClick={event => history.push(`/controllable/${controllableId}/resources/${technicalId}/unavailability/${event.dataItem.internalID}`)}
            gridFields={columns}
        />
    )
}

export default UnavailabilityTable