import { useTranslation } from "react-i18next";
import {
    Button,
    CircularProgress,
    DialogActions,
    DialogTitle,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@mui/material";
import { SubmitHandler, useForm } from "react-hook-form";
import React, { ReactElement, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { ClosableDialog } from "ndr-designsystem";
import useStyles from "./styles";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createIndividualQuota, editIndividualQuota } from "./store/thunks";
import { IndvidualQuotasInfoSchema } from "../../utils/inputChecking";
import { buildMarketPartnerText, getMarketPartnersId } from "../../utils";

interface Props {
    open: boolean;
    handleClose: () => void;
}

interface IAddIndividualQuotaForm {
    inventoryItemId?: string;
    value: number;
    accountingGroupBalancingSchedule: string;
    supplierId: string;
    unit: string;
}

const AddIndividualQuotaDialog = ({ open, handleClose }: Props): ReactElement => {
    const { addNewIndividualQuota } = useAppSelector((state) => state.appState);
    const { currentResource } = useAppSelector((state) => state.controllableResources);
    const { allPartners: marketPartners } = useAppSelector((state) => state.marketPartners);
    const [isSaving, setIsSaving] = useState(false);
    const Schema = IndvidualQuotasInfoSchema(getMarketPartnersId(marketPartners) ?? []);

    const {
        register,
        formState: { errors, isValid },
        handleSubmit,
        reset,
        getValues,
    } = useForm<IAddIndividualQuotaForm>({
        mode: "all",
        resolver: yupResolver(Schema),
        defaultValues: {
            inventoryItemId: addNewIndividualQuota?.data?.inventoryItemId ?? undefined,
            value: addNewIndividualQuota?.data?.value ?? 0,
            accountingGroupBalancingSchedule: addNewIndividualQuota?.data?.accountingGroupBalancingSchedule ?? "",
            supplierId: addNewIndividualQuota?.data?.supplierId ?? "",
            // always % here but its needed by the schema
            unit: "%",
        },
    });

    const { t } = useTranslation();
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const onSubmit: SubmitHandler<IAddIndividualQuotaForm> = async (data) => {
        setIsSaving(true);

        const input = {
            id: currentResource?.inventoryItemId!,
            data,
        };

        let response = false;
        if (addNewIndividualQuota.data !== undefined) {
            response = await dispatch(editIndividualQuota(input)).unwrap();
        } else {
            response = await dispatch(createIndividualQuota(input)).unwrap();
        }

        if (response) {
            reset();
            handleClose();
        }
        setIsSaving(false);
    };

    return (
        <ClosableDialog
            dialogTitle={
                <DialogTitle style={{ paddingLeft: 0 }}>
                    {t(
                        `tables:add_individual_quota_dialog.${
                            addNewIndividualQuota.data != null ? "edit_title" : "title"
                        }`,
                    )}
                </DialogTitle>
            }
            contentPadding={2}
            open={open}
            onClose={handleClose}
        >
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                <FormControl className={classes.formControl} error={errors?.value !== undefined}>
                    <TextField
                        type="number"
                        margin="dense"
                        label={t("tables:add_individual_quota_dialog.value")}
                        fullWidth
                        disabled={isSaving}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                            inputProps: {
                                inputMode: "numeric",
                                step: "any",
                            },
                        }}
                        {...register("value")}
                    />
                    <FormHelperText>{t(`${errors?.value?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <FormControl
                    className={classes.formControl}
                    error={errors?.accountingGroupBalancingSchedule !== undefined}
                >
                    <TextField
                        margin="dense"
                        label={t("tables:add_individual_quota_dialog.accountingGroupBalancingSchedule")}
                        fullWidth
                        disabled={isSaving}
                        {...register("accountingGroupBalancingSchedule")}
                    />
                    <FormHelperText>{t(`${errors?.accountingGroupBalancingSchedule?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <FormControl className={classes.formControl} error={errors?.supplierId !== undefined}>
                    <InputLabel id="demo-simple-select-label">
                        {t("tables:add_individual_quota_dialog.supplier")}
                    </InputLabel>
                    <Select
                        fullWidth
                        label={t("tables:add_individual_quota_dialog.supplier")}
                        defaultValue={getValues().supplierId}
                        disabled={isSaving}
                        {...register("supplierId")}
                    >
                        {marketPartners?.map((val) => (
                            <MenuItem key={val.inventoryItemId} value={val.inventoryItemId}>
                                {buildMarketPartnerText(val)}
                            </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText>{t(`${errors?.supplierId?.message ?? ""}`)}</FormHelperText>
                </FormControl>
                <DialogActions>
                    {!isSaving && (
                        <Button onClick={handleClose} color="primary">
                            {t("tables:add_individual_quota_dialog.buttons.cancel")}
                        </Button>
                    )}
                    <Button type="submit" color="primary" disabled={isSaving || !isValid}>
                        {isSaving ? (
                            <CircularProgress color="primary" variant="indeterminate" />
                        ) : (
                            t("tables:add_individual_quota_dialog.buttons.create")
                        )}
                    </Button>
                </DialogActions>
            </form>
        </ClosableDialog>
    );
};

export default AddIndividualQuotaDialog;
