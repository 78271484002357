enum TaskType {
    "DispatchCostInfoTask" = "DispatchCostInfoTask",
    "DispatchPlanningDataTask" = "DispatchPlanningDataTask",
    "DispatchSensitivitiesTask" = "DispatchSensitivitiesTask",
    "SendDowntimeWorkTask" = "SendDowntimeWorkTask",
    "ImportPrognosisTask" = "ImportPrognosisTask",
    "UpdatePrognosisModelsTask" = "UpdatePrognosisModelsTask",
    "UpdatePrognosisModelsHistoryTask" = "UpdatePrognosisModelsHistoryTask",
    "UpdatePrognosisQualityTask" = "UpdatePrognosisQualityTask",
}

export default TaskType;

export const allowedValues = [
    TaskType.DispatchCostInfoTask,
    TaskType.DispatchPlanningDataTask,
    TaskType.DispatchSensitivitiesTask,
    TaskType.ImportPrognosisTask,
    TaskType.SendDowntimeWorkTask,
    TaskType.UpdatePrognosisModelsTask,
    TaskType.UpdatePrognosisModelsHistoryTask,
    TaskType.UpdatePrognosisQualityTask,
];
