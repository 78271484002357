import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Grid, List } from "@mui/material";
import { includes } from "lodash";
import { CustomButton, CustomTabTitle, isFieldValid, LoadingComponent } from "ndr-designsystem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TransitionToPlannedValueModelStatus from "api/fixed/ControllableResource/TransitionToPlannedValueModelStatus";
import AlertDialog from "Components/AlertDialog/SimpleAlertDialog";
import { DateTime } from "luxon";
import { RootState } from "../../../app/store";
import { useAppDispatch } from "../../../app/hooks";
import { resetCurrentResource, setCurrentResource, setStatus } from "../store/controllableResourcesSlice";
import {
    CRControllabilitySchema,
    CRGeneralTabSchema,
    CRSchema,
    CRTechnicalParametersSchema,
    TRSchema,
} from "../../../utils/inputChecking";
import ControllabilitySubGrid from "./tabs/ControllabilitySubGrid";
import TechnicalParametersSubGrid from "./tabs/TechnicalParametersSubGrid";
import { PageStatus } from "../../../utils/types";
import TechnicalResourcesTable from "./tabs/TechnicalResourcesTable";
import { setCurrentPage } from "../../appStateSlice";
import {
    approveEnhancedControllableResource,
    buildCRState,
    createControllableResourceAction,
    deactivateControllableResource,
    fetchControllableResources,
    fetchControllableResourceStatusById,
    forceRefreshCR,
    resetCRState,
    startTransitionToPlannedValueModel,
    updateControllableResource,
} from "../store/thunks";
import DetailsViewToolbar from "../../../Components/DetailsViewToolbar";
import { areMarketPartnersSame, getMarketPartnersId } from "../../../utils";
import ConnectPlusMsgsTable from "./tabs/ConnectPlusMsgsTable";
import useStyles from "../styles";
import PlanningDataTable from "./tabs/PlanningDataTable";
import ControllableResourceStatus, {
    stableControllableResourceStates,
} from "../../../api/fixed/ControllableResource/ControllableResourceStatus";
import General from "./tabs/General";
import UnavailabilityTable from "./tabs/UnavailabilityTable";
import FlexibilityConstraintsTable from "./tabs/FlexibilityConstraintsTable";
import ActivationsTable from "./tabs/ActivationsTable";
import ProductionDataTable from "./tabs/ProductionDataTable";
import ProgressBar from "./ProgressBar";
import CostInfo from "./tabs/costinfo";
import Sensitivities from "./tabs/sensitivities";
import Status from "./Status";
import SchemaConstants from "../../../utils/schemaConsts";
import { isSSE, isEEG, isEmergencyGenerator, canChangeIntoPlannedValueModel, isFluctuatingCR } from "./utils";
import PlanningDataStatus from "./PlanningDataStatus";
import IndividualQuotasTable from "./tabs/individualQuotas/IndividualQuotasTable";

interface RouteParams {
    id: string;
    page: string;
}

const EDITING_PAGES = ["general", undefined, "controllability", "parameters"];

enum ControllableResourcesPage {
    GENERAL,
    CONTROLLABILITY,
    PARAMETERS,
    RESOURCES,
    MESSAGES,
    "PLANNING-DATA",
    "NETWORK-CONSTRAINTS",
    UNAVAILABILITY,
    ACTIVATIONS,
    PRODUCTION,
    SENSITIVITIES,
    "COST-INFO",
    "INDIVIDUAL-QUOTAS"
}

const ControllableResourceDetailsView: React.FC<{}> = () => {
    const { id, page } = useParams<RouteParams>();
    const { currentResource: resource, allResources } = useSelector((state: RootState) => state.controllableResources);
    const currentResourceIndex = (allResources ?? []).findIndex((r) => r.externalID === resource?.externalID);
    const { allResources: allTRs } = useSelector((state: RootState) => state.technicalResources);
    const { allPartners: marketPartners, currentTenantMarketPartner } = useSelector(
        (state: RootState) => state.marketPartners,
    );
    const pollingInterval = useRef<any>(null);
    const [isSaving, setIsSaving] = useState(false);
    const [pageStatus, setPageStatus] = useState<PageStatus>(PageStatus.VIEW);
    const [AlertOpen, setAlertOpen] = useState(false);
    const isEditing = pageStatus === PageStatus.EDIT;
    const isAdding = pageStatus === PageStatus.ADD;
    const { t } = useTranslation("tables");
    const theme = useTheme();
    const matchesSmallDevice = useMediaQuery(theme.breakpoints.down("lg"), { noSsr: true });
    const dispatch = useAppDispatch();
    const history = useHistory();
    const classes = useStyles();

    const transformPageStringToPageIndex = useCallback(() => {
        if (page) {
            switch (page) {
                default:
                    // @ts-ignore
                    if (ControllableResourcesPage[page.toUpperCase()] === undefined) {
                        history.replace(`/controllable/${id}`);
                        return 0;
                    }

                    // @ts-ignore
                    return ControllableResourcesPage[page.toUpperCase()];
            }
        }
        return 0;
    }, [page, id, history]);

    useEffect(() => {
        dispatch(resetCRState());
    }, [id, dispatch]);

    useEffect(() => {
        if (id !== "add" && pageStatus === PageStatus.VIEW) dispatch(resetCurrentResource(id));
    }, [pageStatus, id, dispatch]);

    useEffect(() => {
        if (pageStatus === PageStatus.EDIT) dispatch(setCurrentPage("EditControllableResource"));
        else if (pageStatus === PageStatus.ADD) dispatch(setCurrentPage("AddControllableResource"));
        else dispatch(setCurrentPage("ControllableResourceDetails"));
    }, [dispatch, pageStatus]);

    useEffect(() => {
        if (id === "add" && resource === undefined) {
            dispatch(
                setCurrentResource({
                    affectedGridOperatorIds:
                        currentTenantMarketPartner != null && currentTenantMarketPartner.inventoryItemId != null
                            ? [currentTenantMarketPartner.inventoryItemId!]
                            : [],
                }),
            );
            setPageStatus(PageStatus.ADD);
        }
    }, [currentTenantMarketPartner, id, dispatch, resource]);

    useEffect(() => {
        const buildState = async (): Promise<void> => {
            if (id !== "add" && marketPartners !== undefined && currentTenantMarketPartner !== undefined) {
                await dispatch(
                    buildCRState({
                        force: false,
                        fetchTRs: true,
                        id,
                    }),
                );
            }
        };

        buildState();
    }, [id, marketPartners, currentTenantMarketPartner, dispatch]);

    useEffect(
        () => () => {
            // eslint-disable-next-line no-restricted-globals
            if (!location.pathname.includes("/controllable/")) {
                dispatch(resetCRState());
                dispatch(setCurrentResource(undefined));
            }
        },
        [id, dispatch],
    );

    const selectTab = (e: any): void => {
        const { selected } = e;

        switch (selected) {
            case ControllableResourcesPage.GENERAL:
                history.replace(`/controllable/${id}`);
                break;
            case ControllableResourcesPage.CONTROLLABILITY:
                history.replace(`/controllable/${id}/controllability`);
                break;
            case ControllableResourcesPage.PARAMETERS:
                history.replace(`/controllable/${id}/parameters`);
                break;
            case ControllableResourcesPage.RESOURCES:
                history.replace(`/controllable/${id}/resources`);
                break;
            case ControllableResourcesPage.MESSAGES:
                history.replace(`/controllable/${id}/messages`);
                break;
            case ControllableResourcesPage["PLANNING-DATA"]:
                history.replace(`/controllable/${id}/planning-data`);
                break;
            case ControllableResourcesPage.SENSITIVITIES:
                history.replace(`/controllable/${id}/sensitivities`);
                break;
            case ControllableResourcesPage["NETWORK-CONSTRAINTS"]:
                history.replace(`/controllable/${id}/network-constraints`);
                break;
            case ControllableResourcesPage.UNAVAILABILITY:
                history.replace(`/controllable/${id}/unavailability`);
                break;
            case ControllableResourcesPage.ACTIVATIONS:
                history.replace(`/controllable/${id}/activations`);
                break;
            case ControllableResourcesPage.PRODUCTION:
                history.replace(`/controllable/${id}/production`);
                break;
            case ControllableResourcesPage["COST-INFO"]:
                history.replace(`/controllable/${id}/cost-info`);
                break;
            case ControllableResourcesPage["INDIVIDUAL-QUOTAS"]:
                history.replace(`/controllable/${id}/individual-quotas`);
                break;
            default:
                break;
        }
    };

    const createControllableResource = async (): Promise<void> => {
        if (resource) {
            try {
                setIsSaving(true);
                await dispatch(createControllableResourceAction(resource)).unwrap();
                history.replace(`/controllable/${resource.externalID}`);
            } catch {
                dispatch(fetchControllableResources(true));
            } finally {
                setIsSaving(false);
            }
            setPageStatus(PageStatus.VIEW);
        }
    };

    const forceRefresh = async (): Promise<void> => {
        try {
            setIsSaving(true);
            await dispatch(resetCRState());
            await dispatch(forceRefreshCR());
        } finally {
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (isSaving) history.replace(`/controllable/${id}`);
    }, [isSaving, history, id]);

    const toggleEditing = (): void => {
        if (isEditing) {
            // eslint-disable-next-line no-alert
            const confirm = window.confirm(t("changesWillBeReverted"));
            if (confirm) {
                setPageStatus(PageStatus.VIEW);
            }
            return;
        }
        setPageStatus(PageStatus.EDIT);
    };

    const getAllResourcesIDs = useCallback(
        (): string[] =>
            (allResources ?? [])
                .filter((r) => r.inventoryItemId !== resource?.inventoryItemId)
                .map((r) => r.externalID ?? ""),
        [allResources, resource],
    );
    const getAllTechnicalResourcesIDs = useCallback(
        (): string[] =>
            (allTRs ?? [])
                .filter((r) => !resource?.technicalResourceIds?.includes(r.inventoryItemId ?? ""))
                .map((r) => r.externalID ?? ""),
        [allTRs, resource],
    );

    const approveEnhancedData = async (): Promise<void> => {
        if (resource && resource.inventoryItemId) {
            setIsSaving(true);
            await dispatch(approveEnhancedControllableResource(resource.inventoryItemId));
            setIsSaving(false);
        }
    };

    useEffect(() => {
        if (
            resource &&
            resource.status &&
            !stableControllableResourceStates.includes(resource.status) &&
            pollingInterval.current == null
        ) {
            pollingInterval.current = setInterval(() => {
                dispatch(fetchControllableResourceStatusById(resource.inventoryItemId ?? ""), 5000);
            }, 5000);
        }

        return () => {
            clearInterval(pollingInterval.current);
            pollingInterval.current = null;
        };
    }, [resource, dispatch]);

    const prepareMP = useCallback(() => getMarketPartnersId(marketPartners), [marketPartners]);

    const isResourceEnhanced = useCallback(() => {
        if (!allTRs) return false;
        if (allTRs.length === 0) return false;
        if (!resource) return false;
        if (
            resource.status !== ControllableResourceStatus.Enhanced &&
            resource.status !== ControllableResourceStatus.Received &&
            resource.status !== ControllableResourceStatus.Created &&
            resource.status !== ControllableResourceStatus.Updated &&
            resource.status !== ControllableResourceStatus.Unknown &&
            resource.status !== ControllableResourceStatus.SendingFailed
        )
            return false;

        if (
            CRSchema(
                prepareMP(),
                marketPartners?.filter((mp) => areMarketPartnersSame(mp, currentTenantMarketPartner)),
                getAllResourcesIDs(),
                getAllTechnicalResourcesIDs(),
            ).isValidSync({
                ...resource,
                technicalResources: allTRs,
            })
        ) {
            if (resource.status !== ControllableResourceStatus.SendingFailed) {
                dispatch(setStatus(ControllableResourceStatus.Enhanced));
            }

            return true;
        }

        return false;
    }, [
        resource,
        allTRs,
        prepareMP,
        dispatch,
        currentTenantMarketPartner,
        getAllResourcesIDs,
        marketPartners,
        getAllTechnicalResourcesIDs,
    ]);

    const handlePreviousClick = (): void => {
        if (currentResourceIndex > 0) {
            history.push(`/controllable/${(allResources ?? [])[currentResourceIndex - 1].externalID ?? ""}/${page}`);
        }
    };

    const handleNextClick = (): void => {
        if (currentResourceIndex < (allResources?.length ?? 0) - 1) {
            history.push(`/controllable/${(allResources ?? [])[currentResourceIndex + 1].externalID ?? ""}/${page}`);
        }
    };

    const saveChanges = async (): Promise<void> => {
        if (resource) {
            setIsSaving(true);
            try {
                await dispatch(updateControllableResource(resource)).unwrap();
                if (
                    includes(
                        [ControllableResourceStatus.Accepted, ControllableResourceStatus.Rejected],
                        resource.status,
                    )
                ) {
                    if (isResourceEnhanced()) {
                        dispatch(setStatus(ControllableResourceStatus.Enhanced));
                    } else {
                        dispatch(setStatus(ControllableResourceStatus.Updated));
                    }
                }
                history.replace(`/controllable/${resource.externalID}`);
                setPageStatus(PageStatus.VIEW);
            } catch {
                dispatch(fetchControllableResources(true));
            } finally {
                setIsSaving(false);
            }
        }
    };
    
    const onDeactivate = async (existenceEnd: DateTime): Promise<void> => {
        if (resource && resource.inventoryItemId) {
            setIsSaving(true);
            await dispatch(deactivateControllableResource({ id: resource.inventoryItemId, existenceEnd }));
            setIsSaving(false);
        }
    };

    const areTRsValid = useCallback(() => {
        if (resource === undefined || resource === null || allTRs === undefined || allTRs === null) return undefined;
        if (allTRs.length === 0) return false;

        return allTRs.every((tr) =>
            TRSchema(
                prepareMP(),
                resource.energySource,
                isSSE(tr),
                !!tr.consumesEnergy,
                !!tr.producesEnergy,
                isEEG(resource),
                !!tr.missingValueForDirection,
                !!tr.missingValueForIncline,
                getAllTechnicalResourcesIDs(),
            ).isValidSync(tr),
        );
    }, [allTRs, resource, prepareMP, getAllTechnicalResourcesIDs]);

    const GeneralTabSchema = CRGeneralTabSchema(
        prepareMP(),
        marketPartners?.filter((mp) => areMarketPartnersSame(mp, currentTenantMarketPartner)),
        getAllResourcesIDs(),
    );

    const TechnicalParametersTabSchema = CRTechnicalParametersSchema();
    const ControllabilityTabSchema = CRControllabilitySchema();

    const isGeneralTabValid = GeneralTabSchema.isValidSync(resource);
    const isTechnicalParametersValid = TechnicalParametersTabSchema.isValidSync(resource);
    const isControllabilityTabValid = ControllabilityTabSchema.isValidSync(resource);

    if (resource === null) return <></>;

    if (resource === undefined || (areTRsValid() === undefined && !isAdding) || !currentTenantMarketPartner) {
        return <LoadingComponent />;
    }
    const isFullyControllable = !isEmergencyGenerator(resource);
     
    const canStartChangeToPlannedValueModel =
        !isAdding &&
        !isEditing &&
        isFluctuatingCR(resource!.energySource!) &&
        resource?.accountingModel?.code !== "Z01" &&
        resource?.transitionToPlannedValueModelStatus !== TransitionToPlannedValueModelStatus.Accepted &&
        resource?.transitionToPlannedValueModelStatus !== TransitionToPlannedValueModelStatus.Started;

    return (
        <List
            disablePadding
            style={{
                pointerEvents: isSaving ? "none" : "all",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <AlertDialog
                title={t("transition_planned_value_model_dialog.title")}
                text={t("transition_planned_value_model_dialog.text")}
                open={AlertOpen}
                acceptText={t("transition_planned_value_model_dialog.buttons.save")}
                cancelText={t("transition_planned_value_model_dialog.buttons.cancel")}
                onAgree={() => {
                    setIsSaving(true);
                    dispatch(startTransitionToPlannedValueModel(resource));
                    setAlertOpen(false);
                    dispatch(fetchControllableResources(true));
                    setIsSaving(false);
                    // reload page
                    history.go(0)                 
                }}
                onCancel={() => {
                    setAlertOpen(false);
                }}
            />
    
            <Status pageStatus={pageStatus} />
            <PlanningDataStatus />
            <ProgressBar />
            <DetailsViewToolbar
                disabled={
                    !isFieldValid(GeneralTabSchema, [SchemaConstants.externalID], { externalID: resource.externalID })
                        .valid || !canChangeIntoPlannedValueModel(resource, allTRs || []).valid 
                }
                pageStatus={pageStatus}
                onEditClick={
                    EDITING_PAGES.includes(page) && resource.status !== ControllableResourceStatus.Sent
                        ? toggleEditing
                        : undefined
                }
                onSaveClick={saveChanges}
                onAddClick={createControllableResource}
                onCancelClick={toggleEditing}
                onRefresh={forceRefresh}
                onDeactivateClick={resource && !resource.receivedFromEiv && resource.isDeactivationAllowed && !resource.existenceEnd ? onDeactivate : undefined}
                onEnhance={
                    isResourceEnhanced() && [0, 1, 2].includes(transformPageStringToPageIndex())
                        ? approveEnhancedData
                        : undefined
                }
                onPreviousClick={currentResourceIndex > 0 ? handlePreviousClick : undefined}
                onNextClick={
                    currentResourceIndex < (allResources?.length ?? 0) - 1 && currentResourceIndex !== -1
                        ? handleNextClick
                        : undefined
                }
                isForGroup={false}
            />
             {canStartChangeToPlannedValueModel && (
                <Grid className={classes.rightGrid} container>
                    <CustomButton
                        title={t("toolbar_buttons.transition_planned_value_mode")}
                        color="primary"
                        onClick={() => {
                            setAlertOpen(true);
                        }}
                    />
                </Grid>
            )}
            <TabStrip
                tabPosition={matchesSmallDevice ? "top" : "left"}
                selected={transformPageStringToPageIndex()}
                className={matchesSmallDevice ? classes.mobileScrollableTabs : classes.scrollableTabs}
                onSelect={selectTab}
            >
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isGeneralTabValid}
                            title={t("controllable_resources_details.tabs.general")}
                        />
                    }
                >
                    {isSaving ? <LoadingComponent /> : <General pageStatus={pageStatus} />}
                </TabStripTab>
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isControllabilityTabValid}
                            title={t("controllable_resources_details.tabs.controllability")}
                        />
                    }
                >
                    <ControllabilitySubGrid pageStatus={pageStatus} />
                </TabStripTab>
                <TabStripTab
                    title={
                        <CustomTabTitle
                            isValid={isTechnicalParametersValid}
                            title={t("controllable_resources_details.tabs.technicalParameters")}
                        />
                    }
                >
                    <TechnicalParametersSubGrid pageStatus={pageStatus} />
                </TabStripTab>
                {!isAdding && !isEditing && (
                    <TabStripTab
                        title={
                            <CustomTabTitle
                                isValid={areTRsValid()!}
                                title={t("controllable_resources_details.tabs.technicalResources")}
                            />
                        }
                    >
                        <TechnicalResourcesTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && (
                    <TabStripTab
                        title={
                            <CustomTabTitle
                                isValid
                                title={t("controllable_resources_details.tabs.connect_messages_success")}
                            />
                        }
                    >
                        <ConnectPlusMsgsTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={
                            <CustomTabTitle isValid title={t("controllable_resources_details.tabs.planning_data")} />
                        }
                    >
                        <PlanningDataTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={
                            <CustomTabTitle
                                isValid
                                title={t("controllable_resources_details.tabs.network_constraints")}
                            />
                        }
                    >
                        <FlexibilityConstraintsTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={
                            <CustomTabTitle isValid title={t("controllable_resources_details.tabs.unavailability")} />
                        }
                    >
                        <UnavailabilityTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={<CustomTabTitle isValid title={t("controllable_resources_details.tabs.activations")} />}
                    >
                        <ActivationsTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={
                            <CustomTabTitle isValid title={t("controllable_resources_details.tabs.production_data")} />
                        }
                    >
                        <ProductionDataTable />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={
                            <CustomTabTitle isValid title={t("controllable_resources_details.tabs.sensitivities")} />
                        }
                    >
                        <Sensitivities />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={<CustomTabTitle isValid title={t("controllable_resources_details.tabs.cost_info")} />}
                    >
                        <CostInfo />
                    </TabStripTab>
                )}
                {!isAdding && !isEditing && isFullyControllable && (
                    <TabStripTab
                        title={<CustomTabTitle isValid title={t("controllable_resources_details.individualQuota.title")} />}
                    >
                        <IndividualQuotasTable />
                    </TabStripTab>
                )}
            </TabStrip>
        </List>
    );
};

export default ControllableResourceDetailsView;
